.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Intro {
  color: white;
  background-color: rgb(3, 11, 22);
  height: calc(100vh - 50px);
  text-align: left;
  overflow: hidden;
}

.Intro-Text {
  padding-top: 30vh;
  padding-right: 100px;
}

.Intro-Image {
  padding-top: 20vh;
}

h3.position {
  font-weight: bold;
  padding-bottom: 20px;
}

.navbar {
  background-color: rgb(3, 11, 22);
  height: 50px;
}

#navbar.scrolled {
  background-color: white;
  color: rgb(3, 11, 22);
  animation: nav-bar-change 1s;
  box-shadow: rgba(43, 83, 135, 0.08) 0px 3px 8px 0px;
}

#navbar.scrolled .navbar-brand {
  color: rgb(3, 11, 22);
}

#navbar.scrolled .nav-link {
  color: rgb(3, 11, 22);
}

@keyframes nav-bar-change {
  from {background-color: rgb(3, 11, 22);}
  to {background-color: white;}
}

.collapse {
  justify-content: flex-end;
}

.Portfolio {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: left;
  background-color: rgb(245, 245, 245);
}

.btn-row {
  margin-right: 30px;
  border: none;
}

.project-item {
  padding: 10px;
  transition: .5s;
}

.hidden {
  display: none;
}

.project-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.project-image {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.experience {
  background-color: rgb(245, 245, 245);
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: left;
}

ul {
  padding-left: 20px;
}

.experience-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 100%;
}

.experience-image {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

h1 {
  text-decoration: underline 3px rgb(3, 11, 22);
}

.btn-check:checked+.btn-outline-primary {
  background-color: rgb(3, 11, 22) !important;
  border-color: rgb(3, 11, 22) !important;
  color:white;
}

.btn-outline-primary {
  background-color: white !important;
  border-color: rgb(3, 11, 22) !important;
  color:rgb(3, 11, 22);
  outline: none !important;
}

.btn-outline-primary:hover {
  background-color: rgb(3, 11, 22) !important;
  border-color: rgb(3, 11, 22) !important;
  color:white;
}

.education {
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: left;
}

.footer {
  background-color: rgb(3, 11, 22);
  padding-top: 50px;
  padding-bottom: 50px;
  color: white;
}

.large-icon {
  font-size: 60px;
}